import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Link from '../utils/link'
import Seo from '../components/seo'
import RenderBlock from '../utils/render-block'

class RangeTemplate extends Component {

  openRange(){
    document && document.querySelector('.header__nav').classList.add('range-active')
  }

  render() {
    let page = this.props.data.wpRange

    return (
      <>
        <Seo
          bodyClass='shop-archive'
          {...page.seo}
        />
        <section className='breadcrumbs'>
          <div className='breadcrumbs__inner'>
            <ul>
              <li><Link to='/'>Home</Link></li>
              <li><button type='button' onClick={this.openRange}>Our Range</button></li>
              <li>{page.title}</li>
            </ul>
          </div>
        </section>
        { page.acf.components && page.acf.components.map((el, i) => RenderBlock(el.fieldGroupName, el, i, 'range'))  }
      </>
    )
  }
}

export const rangeQuery = graphql`
  query ($id: String!) {
    wpRange(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
        schema {
          raw
        }
      }
      acf {
        components {
          ... on WpRange_Acf_Components_HeroBanner {
            fieldGroupName
            leftImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 1200
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            rightImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 1200
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            overlay {
              title
              button {
                text
                link
              }
            }
            title
            content
            button {
              text
              link
            }
          }
          ... on WpRange_Acf_Components_CircleImageItems {
            fieldGroupName
            title
            layout
            content
            items {
              title
              content
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 75
                      width: 200
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
              link
              text
            }
          }
          ... on WpRange_Acf_Components_HowItWorks {
            fieldGroupName
            title
            items {
              title
              content
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 75
                      width: 200
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
            }
            buttons {
              button {
                text
                link
              }
            }
          }
          ... on WpRange_Acf_Components_WhoWeWorkWith {
            fieldGroupName
            title
            content
            items {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 75
                      width: 600
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
              logo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 75
                      width: 600
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
              title
              socialHandle
              link
            }
          }
          ... on WpRange_Acf_Components_FrequentlyAskedQuestions {
            fieldGroupName
            title
            questions {
              title
              content
            }
          }
          ... on WpRange_Acf_Components_ReadyToGetStarted {
            fieldGroupName
          }
          ... on WpRange_Acf_Components_Gallery {
            fieldGroupName
            title
            items {
              panel {
                topLeftImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 75
                        width: 800
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: DOMINANT_COLOR
                      )
                    }
                  }
                }
                topCentreImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 75
                        width: 800
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: DOMINANT_COLOR
                      )
                    }
                  }
                }
                topRightImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 75
                        width: 800
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: DOMINANT_COLOR
                      )
                    }
                  }
                }
                bottomLeftImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 75
                        width: 800
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: DOMINANT_COLOR
                      )
                    }
                  }
                }
                bottomCentreImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 75
                        width: 800
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: DOMINANT_COLOR
                      )
                    }
                  }
                }
                bottomRightImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 75
                        width: 800
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: DOMINANT_COLOR
                      )
                    }
                  }
                }
              }
            }
          }
          ... on WpRange_Acf_Components_RangeHeader {
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 750
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            icon {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 120
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            title
            content
            text
            link
            images {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 75
                      width: 72
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default RangeTemplate
